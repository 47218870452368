<template>
    <div style="width: 500px;height: 300px;">
<!--        三种类型的参数我这里是写死的，实际是需要动态获取的，其中注意 OrderType 只有支付的时候需要传-->
<!--        payType 在转账跟分账的时候需要传 支付类型1红包问答2悬赏资料3悬赏讨论4必选竞标5转账-->
<!--        不管哪种类型@change事件用来获取orderNo的ref用来获取验证码-->

        <!--        支付类型参考-->
        <aa-da-pay-code :type="type"  :payAmt="0.1" :OrderType="'1'" @change="changeOrder($event)" ref="child"></aa-da-pay-code>
        <!--        转账类型参考-->
<!--        <aa-da-pay-code :type="type" :uuid="'1ef48-10323-4a87-bc41-11fd8'" :payAmt="1" :payType="'5'" @change="changeOrder($event)" ref="child"></aa-da-pay-code>-->
        <!--        分账类型参考-->
<!--        <aa-da-pay-code :type="type" :applyId="'0461740800356360192'" :orderNo="'20230330_184056_733514'" :payAmt="0.1" :payType="'1'" @change="changeOrder($event)" ref="child"></aa-da-pay-code>-->

        <div style="margin-left: 180px;margin-top: 100px;">
            <el-button type="primary" @click="bankPay">立即充值</el-button>
        </div>




        <div v-for="(item,index) in vipList" :key="index">
            <div @click="SetMeal(index)" :style="item.checked == 1?'background: #018679':''" style="width: 50px;height: 20px;border: 1px solid sandybrown;">{{item.value}}</div>
        </div>
    </div>
</template>

<script>
    import AaDaPayCode from "../../components/common/Pay/AaDaPayCode";
    export default {
        name: "test1",
        components:{
            AaDaPayCode
        },
        computed:{
            user() {
                return this.$store.state.user;
            },
        },
        data(){
            return{
                type:'1',
                orderNo:'',
                loading:false,
                vipList:[
                    {
                        value:1,
                        checked:0
                    },
                    {
                        value:2,
                        checked:0
                    },
                    {
                        value:3,
                        checked:0
                    }
                ]
            }
        },
        mounted() {

        },
        methods:{
            SetMeal(index){
                var that = this;
                for (var i = 0; i < that.vipList.length; i++){
                    that.vipList[i].checked = 0;
                    if(i == index){
                        that.vipList[i].checked = 1;
                    }
                }
            },
            //子组件传过来的订单号 此方法在父页面必写
            changeOrder(order){
                this.orderNo = order;
            },
            //父组件获取子组件的验证码 this.$refs.child.childMethods() 必写
            bankPay(){
                var that = this;
                var obj =  this.$refs.child.childMethods();
                if(obj.code == ""){
                    that.utils.err("请填写验证码");
                    return false;
                }
                if(obj.payPwd == ""){
                    that.utils.err("请填写密码");
                    return false;
                }

                var params = {};
                params.orderNo = that.orderNo;
                params.code = obj.code;
                params.payPwd = obj.payPwd;
                if(parseInt(that.type) == 1){
                    that.newApi.alpplyBankCardPayDetermine(params).then((res) => {
                        if(res.isSuccess == 1){
                            that.loading = true;
                        }
                    })
                }else if(parseInt(that.type) == 2){
                    that.newApi.settleAccountTransferDetermine(params).then((res) => {
                        if(res.isSuccess == 1){
                            that.loading = true;
                        }
                    })
                }else if(parseInt(that.type) == 3){
                    that.newApi.settleAccountPennyDetermine(params).then((res) => {
                        if(res.isSuccess == 1){
                            that.loading = true;
                        }
                    })
                }

            },
        }
    }
</script>

<style scoped>
</style>